<template>
  <div class="main-container">
    <div class="relative z-10 py-32">
      <div class="text-left max-w-2xl px-4 container mx-auto prose md:prose-xl">
        <p class="tracking-wider font-bold">PERKENALAN SINGKAT</p>
        <div id="introduction-content">...</div>

        <p class="tracking-wider font-bold">VISI, MISI, &amp; TUJUAN</p>
        <div id="principle-content">...</div>
      </div>
    </div>

    <!-- divider -->
    <div class="custom-shape-divider-bottom-1609601720">
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
      >
        <path
          d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
          class="shape-fill"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import marked from "marked";
import DOMPurify from "dompurify";

export default {
  methods: {
    async fetchProfile() {
      Promise.all([
        Axios.get("api/profile/introduction"),
        Axios.get("api/profile/education-principle"),
      ]).then((responses) => {
        this.$nextTick(() => {
          if (responses[0].data.introduction.content !== null) {
            document.getElementById(
              "introduction-content"
            ).innerHTML = DOMPurify.sanitize(
              marked(responses[0].data.introduction.content)
            );
          }

          if (responses[1].data.principle.content !== null) {
            document.getElementById(
              "principle-content"
            ).innerHTML = DOMPurify.sanitize(
              marked(responses[1].data.principle.content)
            );
          }
        });
      });
    },
  },
  mounted() {
    this.fetchProfile();
  },
  name: "ShortIntroduction",
};
</script>

<style lang="scss" scoped>
.main-container {
  @apply pb-2  p-2 z-10 relative bg-white;
}

// divider
.custom-shape-divider-bottom-1609601720 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1609601720 svg {
  position: relative;
  display: block;
  width: calc(109% + 1.3px);
  height: 165px;
}

.custom-shape-divider-bottom-1609601720 .shape-fill {
  fill: #059669;
}

/** For mobile devices **/
@media (max-width: 767px) {
  .custom-shape-divider-bottom-1609601720 svg {
    width: calc(122% + 1.3px);
    height: 93px;
  }
}
</style>